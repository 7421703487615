<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>商城管理</el-breadcrumb-item>
            <el-breadcrumb-item>商品分类</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 表头 -->
      <el-row class="content-box">
         <!-- 主体内容 -->
         <div class="table-box">
            <div class="table-head-btn">
               <el-row></el-row>
               <el-row class="right-btn">
                  <el-button class="bg-gradient" type="primary"  @click="handleAdd">新建分类</el-button>
               </el-row>
            </div>
            <el-table
                  ref="mainTable"
                  v-loading="loading"
                  :data="tableData"
                  highlight-current-row
                  @row-click="(row) => handleCurrentChange(row)"
                  row-key="id"
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                  style="width: 100%"
                  :stripe="true"
                  :load="getCategoryChild"
                  border fit lazy>
               <el-table-column label="图标">
                  <template slot-scope="scope">
                     <img v-if="scope.row.icon" style="width: 35px;height: 35px;border-radius: 50%" :src="scope.row.icon" alt="">
                     <img v-else style="width: 35px;height: 35px;border-radius: 50%" src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" alt="">
                  </template>
               </el-table-column>
               <el-table-column prop="name" label="分类名称" min-width="120"></el-table-column>
               <el-table-column prop="sortNo" label="排序号"></el-table-column>
               <el-table-column prop="createTime" label="创建时间"></el-table-column>
               <el-table-column :label="$t('msg.operating')" min-width="140" sortable fixed="right">
                  <template slot-scope="scope">
                     <el-button  type="text" @click.stop="handleEdit(scope.row)" >
                        <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                     </el-button>
                     <el-button type="text" style="color: #F56C6C;" @click.stop="handleDel(scope.row.id)" >
                        <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
         </div>
         <!-- 新增/编辑弹窗 -->
         <el-dialog title="新建分类" :visible.sync="isShow" width="550px" :before-close="handleClose">
            <el-form class="form-dialog-box" :model="ruleForm" :rules="rules" ref="ruleForm">
               <el-form-item style="width:100%" label="图标">
                  <template>
                     <el-radio v-model="radioIcon" label="1"><span v-text="$t('msg.font_icon')">字体图标</span></el-radio>
                     <el-radio v-model="radioIcon" label="2"><span v-text="$t('msg.upload_icon')">上传图标</span></el-radio>
                  </template>
               </el-form-item>
               <el-form-item>
                  <el-input  class="width-300" v-if="radioIcon === '1'" :placeholder="$t('msg.font_icon')" clearable></el-input>
                  <div v-else class="cate-upload">
                     <el-upload
                           class="upload-demo"
                           :limit="1"
                           drag
                           accept="image/png"
                           action="#"
                           :auto-upload="false"
                           :on-change="handleChange">
                        <p style="float: left;line-height: 31px">上传图标</p>
                        <i class="el-icon-upload"></i>
                     </el-upload>
                     <div class="old-icon" v-if="oldIconShow">
                        <p><i class="el-icon-document"></i>{{oldIcon}}</p>
                        <span @click="oldIconShow = false" class="el-icon-close"></span>
                     </div>
                  </div>
               </el-form-item>
               <el-form-item label="分类名称" prop="name">
                  <el-input  class="width-300" v-model="ruleForm.name" placeholder="分类名称" clearable></el-input>
               </el-form-item>
               <el-form-item label="排序号" prop="sortNo">
                  <el-input  class="width-300" v-model="ruleForm.sortNo" placeholder="排序号" clearable></el-input>
               </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
               <el-button  @click="resetForm" v-text="$t('msg.reset')">重置</el-button>
               <el-button class="bg-gradient" type="primary" @click="handleSave" v-text="$t('msg.save')">保存</el-button>
            </span>
         </el-dialog>
      </el-row>
   </div>
</template>

<script>
import { urlObj } from '@/api/interface'
import { mapState } from "vuex";
import { CustomArray } from '@/common/js/common'

export default {
   data(){
      return{
         tableData: [],      // 表格数据
         loading: true,      // 动画加载效果
         isShow: false,      // 是否显示弹窗
         action: 'add',      // 当前操作行为
         radioIcon: '1',     // 当前选中上传图标
         oldIcon: '',        // 旧图标
         oldIconShow: false, // 旧图标隐藏/显示
         id: '',
         fileForm: {
            file: null,
            module: 'goods',
            menu: 'category',
            func: 'icon',
            category: 'icon',
            isThumb: 'true'
         },
         ruleForm: {
            name: '',           // 分类名称
            sortNo: '',         // 排序号
            level: 1,           // 分类层级
            leaf: false,        // 是否子节点
            parentId: ''        // 父id
         },
         rules: {
            name: [{ required: true, message: '请输入分类名称', trigger: 'blur' }],
            sortNo: [{ required: true, message: '请输入排序号', trigger: 'blur' }],
         },
         file: null,         // 文件资源
         sort_no_null: '排序号不能为空！',
         add_success: "添加成功！",
         del_success: "删除成功！",
         update_success: "修改成功！",
         confirm: '确定',
         cancel: '取消',
         confirm_remove: '确定移除？',
         prompt: '提示！'
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getCategory()
   },
   inject: ['reload'],
   methods: {
      // 获取商品分类列表
      getCategory(level = 1, parentId, callback){
         const url = urlObj.getGoodsCategory
         const param = { isDelete: 'NO',companyId :this.hotelInfo.storeId, level, parentId }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success){
               this.loading = false
               let tableData = res.children
               tableData.forEach(item => item.icon = item.icon && window.getResourcesHost() + item.icon)
               let customArray = new CustomArray(tableData)
               tableData = customArray.changeKey('hasChildren', 'leaf')
               if (level === 1) this.tableData = tableData
               else callback(tableData)
            }
         })
      },
      // 获取商品分类子列表
      getCategoryChild(tree, treeNode, resolve) {
         this.getCategory(tree.level + 1, tree.id,(tableData) => resolve(tableData))
      },
      // 选中当前行
      handleCurrentChange: (() => {
         let id
         return function (row) {
            if (row.id !== id) {
               id = row.id
               this.ruleForm.parentId = row.id
               this.ruleForm.level = row.level + 1
               this.ruleForm.leaf = true
            }else {
               id = ''
               this.ruleForm.parentId = ''
               this.ruleForm.leaf = false
               this.$refs.mainTable.setCurrentRow();
            }
         }
      })(),
      // 获取上传的文件资源
      handleChange(file){
         this.fileForm.file = file.raw
      },
      // 添加分类
      handleAdd(){
         this.isShow = true
         this.action = 'add'
      },
      // 编辑分类
      handleEdit(row){
         this.isShow = true
         this.action = 'edit'
         this.ruleForm.name = row.name
         this.ruleForm.sortNo = row.sortNo
         // 图标默认选中
         if (row.hasOwnProperty('icon')){
            if (row.icon.substr(-3) === 'png'){
               //this.radioIcon = '2'
               this.oldIconShow = true
               const arr = row.icon.split('/')
               this.oldIcon = arr[arr.length-1]
            }
         }
         // 编辑用id
         this.id = row.id
      },
      // 重置表单
      resetForm(){
         this.ruleForm.name = ''
         this.ruleForm.sortNo = ''
         // 图标
         this.fileForm.file = null
         this.oldIcon = ''
         this.oldIconShow = false
      },
      // 保存菜单
      handleSave(){
         this.$refs.ruleForm.validate(valid => {
            if (!valid) return
            let url = urlObj.saveCategory
            let param = new FormData()
            if (this.fileForm.file) for (const k in this.fileForm) param.set(k, this.fileForm[k])
            for (const k in this.ruleForm) param.set(k, this.ruleForm[k])
            if (this.action === 'edit') {
               param.append('id', this.id)
               param.delete('leaf')
               param.delete('level')
               param.delete('parentId')
            }
            this.$axios.post(url, param, 'file').then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.action === 'add' ? this.add_success : this.update_success,
                     type: 'success'
                  })
                  this.getCategory()
                  this.handleClose()
               }
            })
         })
      },
      // 关闭弹窗
      handleClose(){
         this.isShow = false
         this.resetForm()
      },
      // 删除菜单
      handleDel(id){
         this.$confirm(this.confirm_remove, this.prompt, {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'warning'
         }).then(() => {
            let param = new FormData()
            param.append('id', id)
            param.append('isDelete', 'YES')
            const url = urlObj.saveCategory
            this.$axios.post(url, param, 'file').then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.del_success,
                     type: 'success'
                  })
                  this.getCategory()
               }
            })
         })
      },
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         newVal.id !== oldVal.id && this.getCategory()
      }
   },
}
</script>

<style>
   .cate-upload{
      position: relative;
   }
   .cate-upload .upload-demo{
      height: 32px;
   }
   .cate-upload .el-upload-dragger{
      width: 260px;
      height: 32px;
      text-align: right;
      border: solid 1px #DCDFE6;
      padding: 0 5px 0 16px;
      font-size: 13px;
      border-radius: 4px;
      color: #cccccc;
   }
   .cate-upload .el-upload-dragger .el-icon-upload{
      line-height: 29px;
      margin: 0;
      font-size: 16px;
   }
   .cate-upload .old-icon{
      top: 0;
      position: absolute;
      width: 260px;
      display: flex;
      justify-content: space-between;
      border-radius: 4px;
      padding: 0 4px;
      background: #f5f7fa;
      box-sizing: border-box;
   }
   .cate-upload .old-icon p{
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      line-height: 32px;
      text-overflow:ellipsis;
   }
   .cate-upload .old-icon p i{
      margin: 0 4px 0 0;
   }
   .cate-upload .old-icon span{
      line-height: 32px;
   }
   .cate-upload .el-upload-list{
      position: absolute;
      top: 0;
      width: 260px;
      background: #f5f7fa;
   }
   .cate-upload .el-upload-list li{
      margin-top: 0!important;
      line-height: 32px;
   }
   .cate-upload .el-upload-list__item .el-icon-close{
      top: 9px;
   }
</style>
